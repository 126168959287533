import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AgmCoreModule, GoogleMapsAPIWrapper} from '@agm/core';
import { CrudService } from '../app/core/services/shared/crud.service';
import { FeatureService } from '../app/core/services/feature.service'
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { RequestService } from '../app/core/services/shared/request.service';
import { AuthenticationModule } from './core/auth/authentication.module';
import { AuthenticationService } from './core/auth/authentication.service';
import { TokenStorage } from './core/auth/token-storage.service';
import { UtilsService } from './core/services/utils.service';
import { LayoutConfigStorageService } from './core/services/layout-config-storage.service';
import { TranslateModule } from '@ngx-translate/core';
import {TranslationService} from './core/services/translation.service';
import { UserService } from '../app/core/services/security/users.service';
import { OrderService } from '../app/core/services/order.service';
import { SettingService } from '../app/core/services/setting.service';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GestureConfig, MatProgressSpinnerModule, MatPaginatorIntl } from '@angular/material';
import {MatTooltipModule} from '@angular/material/tooltip';

import {
  SocialLoginModule, 
  AuthServiceConfig,
  GoogleLoginProvider, 
  FacebookLoginProvider, 
  LinkedinLoginProvider,
  AuthService
} from 'ng4-social-login';
import { from } from 'rxjs';
import { SplashScreenService } from './core/services/splash-screen.service';
import { HeaderSharedService } from './core/services/shared/header-shared.service';
import { Authorization } from './core/services/shared/authorization';
import { LayoutConfigService } from './core/services/layout-config.service';
import { ArabicPaginatorIntl } from './core/services/shared/arabic-paginator-intl';
import { ProductService } from './core/services/ProductService';


const CONFIG = new AuthServiceConfig([
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider('2859470334124895')
  },
], true);

export function provideConfig() {
  return CONFIG;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpModule,
    OverlayModule,
    SocialLoginModule,
    MatProgressSpinnerModule,
		MatTooltipModule,
    TranslateModule.forRoot(),
    AgmCoreModule.forRoot({
			apiKey: 'AIzaSyDs_00oRVkEFxaGN0Y--Nu36ga1z-z6xUg'

		})
  ],
  providers: [
      GoogleMapsAPIWrapper,
      CrudService,
      RequestService,
      FeatureService,
      AuthService,
      UserService,
      OrderService,
      SettingService,
      AuthenticationModule,
      AuthenticationService,
      TokenStorage,
      UtilsService,
      UserService,
      TranslationService,
      LayoutConfigStorageService,
      {provide: AuthServiceConfig, useFactory: provideConfig},
      SplashScreenService,
      HeaderSharedService,
      Authorization,
      LayoutConfigService,
      { provide: MatPaginatorIntl, useClass: ArabicPaginatorIntl },
      ProductService

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

